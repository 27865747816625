import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Footer = () => {
  // Google Maps coordinates
  const center = { lat: 28.6280, lng: 77.3649 }; // Coordinates for the location

  return (
    <footer className="bg-primary text-white p-4">
      <div className="container">
        <div className="row">
          {/* Left Column: Contact Information */}
          <div className="col-md-6">
            <h5>Contact Us</h5>
            <p>
              <strong>Address:</strong><br />  First Floor, C-56/32, 
              <br />
              C Block, Phase 2, Industrial Area, 
              <br />
              Sector 62, Noida, Uttar Pradesh 201309
            </p>
            <p>
              <strong>Email:</strong><br />
              <a href="mailto:sales@drifttechsol.com" className="text-white">sales@drifttechsol.com</a>
            </p>
            <p>
  <strong>Mobile:</strong><br />
  <a href="tel:+917428396219" className="text-white">+917428396219</a>
</p>
          </div>

          {/* Right Column: Google Map */}
          <div className="col-md-6">
            <div style={{ height: '300px', width: '100%' }}>
              <LoadScript googleMapsApiKey="AIzaSyDKQUwAaW0wQGBlB05FDyNvMzH2W-NcF0c">
                <GoogleMap
                  mapContainerStyle={{ height: '100%', width: '100%' }}
                  center={center}
                  zoom={15}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
      
      {/* Copyright Section */}
      <div className="text-center mt-3">
        <p>© 2024 Drift Tech Solution. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
